import React from 'react';
import { graphql, Link } from 'gatsby';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => (
  <>
    <SEO title={frontmatter.title} description={frontmatter.description} />
    <Layout
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      button={frontmatter.button}
    >
      <section className="page-section index-section-one">
        <Container
          className="mb-5"
          style={{ marginTop: 64, paddingBottom: 96 }}
        >
          <Row>
            <Col className="index-section-one-left" xs={12} lg>
              <img src="/img/homepage.png" />
            </Col>
            <Col className="ml-lg-5" xs={12} lg>
              <h2 style={{ fontSize: 62, fontWeight: 'bold' }}>About Daexus</h2>
              <p
                style={{
                  color: '#000000',
                  fontWeight: 300,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }}
              >
                We’re a group of people who are passionate about data and want
                to make the world a better place by providing a meaningful
                difference. Focused on security and ease of use, Daexus offers
                technology that frees ‘captive’ data.
              </p>
              <br />
              <Button as={Link} to="/products/" variant="secondary">
                See more
                 
              </Button>
              <br />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="page-section index-section-two bg-primary text-light">
        <Container>
          <h2
            className="text-center pt-5"
            style={{ fontSize: 62, fontWeight: 'bold' }}
          >
            How it works
          </h2>
		  <p
            className="text-secondary text-center pb-5"
            style={{ fontWeight: 300 }}
          >
            Accessing your Adobe Analytics, Google Analytics, Jira or Facebook Ads data in Tableau as easy as 1-2-3
          </p>
          <Row>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/icon-step-1@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  <br />
                  Step 1
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300, textAlign: 'center' }}>
                  Open Tableau
				  <br />
				  (2019.4 and later)
                </p>
              </Row>
            </Col>
            <Col className="p-5 step2" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/icon-step-2@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  <br />
                  Step 2
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300, textAlign: 'center' }}>
                  Open the web data connector link in Tableau{' '}
				  <br />
				  <br />
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/icon-step-3@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  <br />
                  Step 3
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300, textAlign: 'center' }}>
                  Select your Adobe Analytics, Jira, Google Analytics or Facebook Ads data
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="page-section index-section-three">
        <Container>
          <h2
            className="text-center py-5"
            style={{ fontSize: 62, fontWeight: 'bold' }}
          >
            Benefits for your company
          </h2>
          <Row>
            <Col xs={12} lg={6} style={{ padding: 0, height: 400 }}>
              <Row className="block align-content-center align-items-center">
                <Col xs={6} lg={4}>
                  <img src="/img/analytics-icon@2x.png" />
                </Col>
                <Col xs={12} lg>
                  <p className="block-title">BI/Analytics</p>
                  <ul>
                    <li>
                      Quickly and easily import Adobe Analytics, Jira, Google Analytics or Facebook Ads data in Tableau
                    </li>
                    <li>Deliver quality insights</li>
                    <li>Save time by automating data imports</li>
                    <li>Helps display data in a consumable way</li>
                    <li>Safely share dashboards</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={6} style={{ padding: 0, height: 400 }}>
              <Row className="block align-content-center align-items-center">
                <Col xs={6} lg={4}>
                  <img src="/img/marketing-icon@2x.png" />
                </Col>
                <Col xs={12} lg>
                  <p className="block-title">Marketing</p>
                  <ul>
                    <li>Visualize and compare campaign performance</li>
                    <li>Easily map marketing efforts to results</li>
                    <li>Validate test scenarios</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={6} style={{ padding: 0, height: 400 }}>
              <Row className="block align-content-center align-items-center">
                <Col xs={6} lg={4}>
                  <img src="/img/it-icon@2x.png" />
                </Col>
                <Col xs={12} lg>
                  <p className="block-title">IT</p>
                  <ul>
                    <li>
                      Fewer support requests from other teams regarding data
                      imports
                    </li>
                    <li>Build live product health dashboards</li>
                    <li>A/B Testing reports</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={6} style={{ padding: 0, height: 400 }}>
              <Row className="block align-content-center align-items-center">
                <Col xs={6} lg={4}>
                  <img src="/img/managers-icon@2x.png" />
                </Col>
                <Col xs={12} lg>
                  <p className="block-title">Managers &amp; Directors</p>
                  <ul>
                    <li>Identify growth and revenue opportunities</li>
                    <li>High level overview</li>
                    <li>Get insights at a glance</li>
                    <li>
                      Quickly visualize ROI at company, department or product
                      level
                    </li>
                    <li>Always up-to-date</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="page-section bg-secondary text-dark">
        <Container>
          <p className="text-center text-primary pt-5" style={{ fontSize: 24 }}>
            Make the most of our connector and its exclusive features by signing
            up for the premium version
          </p>
          <Row>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/time-saver@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Saved
                  <br />
                  credentials
                </p>
              </Row>
            </Col>
            <Col className="p-5 step2" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/dates@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Rolling
                  <br />
                  dates
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/updates@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Scheduled
                  <br />
                  refreshes
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/control@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Complete
                  <br />
                  control
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/fast-forward.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Speed
                  <br />
                  it up
                </p>
              </Row>
            </Col>
            <Col xs={12} className="text-center pb-5">
              <Button
			    type="button"
                variant="outline-primary"
                as={Link}
                to="/products/#online"
              >
                Try it out now&nbsp;&nbsp;
				
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  </>
);

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        subheading
        button
        description
      }
    }
  }
`;
